import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService {
  private history: string[] = [];

  constructor(private router: Router) {}

  public initRoutingState(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.history.length > 4) {
          // Remove first value to prevent bloating the array
          this.history.shift();
        }
        this.history = [...this.history, event.url];
      }
    });
  }

  /**
   * Gives the previous URL from the navigation history.
   */
  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/watchtower';
  }
}
